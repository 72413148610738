import { Collapse, Snackbar } from '@mui/material'
import { Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { getErrorNotice, getNotice, Notice } from '../../../../utils/notices/notices'
import { useResetNotice } from './useAppNotice'

interface ILocationStateMsg {
  message: {
    severity?: string
    msgKey?: Notice
    msg?: string
  }
}

export const AppNotice: React.FC = () => {
  const { state } = useLocation()
  const { hash } = useLocation()
  const resetNotice = useResetNotice()

  const [message, setMessage] = useState<string>(window.CP_APP_ERRORS || window.CP_APP_NOTICE || '')
  const [isError, setIsError] = useState<boolean>(!!window.CP_APP_ERRORS)
  const [isOpen, setIsOpen] = useState(!!message)

  useEffect(() => {
    const noticeKey = (hash == null ? '' : hash.substring(1)) as Notice
    const notice = getNotice(noticeKey)
    if (notice) {
      setIsError(getErrorNotice(noticeKey))
      setMessage(notice)
      setIsOpen(true)
    }
  }, [hash])

  useEffect(() => {
    if (state) {
      const stateMsg = (state as ILocationStateMsg).message
      let msg
      if (stateMsg?.msgKey) {
        msg = getNotice(stateMsg.msgKey)
      } else if (stateMsg?.msg) {
        msg = stateMsg.msg
      }
      if (msg) {
        setIsError(stateMsg.severity === 'error')
        setMessage(msg)
        setIsOpen(true)
      }
      resetNotice()
    }
  }, [state, resetNotice])

  return (
    <Collapse in={isOpen} sx={{ width: '100%', maxWidth: '968px' }}>
      <Snackbar
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={isError ? null : 10000}
        data-testid="app-notice"
      >
        <Alert
          severity={isError ? 'error' : 'success'}
          onClose={() => setIsOpen(false)}
          sx={{ border: '1px solid currentColor', marginBottom: 2 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Collapse>
  )
}
